import { Container, Box, Typography, TextField, Button, styled, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WifiIcon from '@mui/icons-material/Wifi';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { FormattedMessage } from 'react-intl';
import { loginDefault, loginWithEmail } from '../../../../services/user';
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { API_PORTAL_URL } from '../../../../constants/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LanguageSelector from '../pre-registro/LanguageSelector';
import { LOCALES } from '../../../../lang';
import { GetAllSettings, GetSelectedBanner } from '../../../../services';
import { updateIncrementClicksBanner, updateIncrementRejectBanner } from '../../../../services';
import './styles.css';
import FloatingImageWhite from '../../../FloatingImageWhite';

const LogInSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    name: Yup.string().min(1),
});

const TextInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '1.25rem',
        borderColor: 'white',
        '& legend': {
            '& span': {
                color: 'white'
            }
        }
    },
    '@media (max-width: 768px)': {
        width: '100%',
    },
    '@media (max-width: 480px)': {
        width: '100%',
    },
}));

interface LinkedInResponse {
    email: string;
}
interface ILogInForm {
    email: string;
    password: string;
    name: string;
}

interface ILogIn {
    isMobile?: boolean;
    currentLocale: string;
    setLocale: (locale: string) => void;
    defaultTitleLanguage: string;
    allTitle: any[];
    changeLenguage: (locale: string) => void;
    banners: any[];
}

const PreLogIn = ({ isMobile, currentLocale, setLocale, defaultTitleLanguage, allTitle, changeLenguage, banners }: ILogIn) => {

    const [selectedLanguage, setSelectedLanguage] = useState<string>('');

    const [titleAllLanguage, setTitleAllLanguages] = useState(allTitle);
    const [title, setTitle] = useState(defaultTitleLanguage);

    const spanishLanguage = { ...titleAllLanguage.filter((r: any) => r.language === "spanish")[0] };
    const englishLanguage = { ...titleAllLanguage.filter((r: any) => r.language === "english")[0] };
    const portugueseLanguage = { ...titleAllLanguage.filter((r: any) => r.language === "portuguese")[0] }

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [defaultImage, setDefaultImage] = useState<string>('');
    const [video, setVideo] = useState<boolean>(false);

    const [activeFacebook, setActiveFacebook] = useState<boolean>(false);
    const [activeLinkedin, setActiveLinkedin] = useState<boolean>(false);
    const [activeGoogle, setActiveGoogle] = useState<boolean>(false);

    const [activeEmail, setActiveEmail] = useState<boolean>(true);
    const [activePassword, setActivePassword] = useState<boolean>(true);
    const [activeOnly_rs, setActiveOnly_rs] = useState<boolean>(true);

    const [inviteMode, setInviteMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [searchParams] = useSearchParams()
    const encodedParams = window.btoa(searchParams.toString()) || null

    const changeTitlesLanguage = (language: string) => {
        switch (language) {
            case LOCALES.SPANISH:
                setTitle(spanishLanguage.title)
                break;
            case LOCALES.ENGLISH:
                setTitle(englishLanguage.title)
                break;
            case LOCALES.PORTUGUESE:
                setTitle(portugueseLanguage.title)
                break;
            default:
                setTitle(spanishLanguage.title)
                break;
        }
    }

    useEffect(() => {
        if (!banners || banners.length == 0) {
            const getAll = async () => {
                let res = await GetSelectedBanner();
                if (res && res.pre_login) {
                    if (res.banner_type != "imagen") {
                        setVideo(true);
                    }
                    banners.push(res);
                }

            }
            getAll();
        } else {
            if (banners[currentIndex].banner_type != "imagen") {
                setVideo(true);
            }
        }
        const getSettings = async () => {
            const settings: any[] = await GetAllSettings();

            if (settings && settings.length > 0) {
                if (settings[0].file_encoded) {
                    setDefaultImage(settings[0].file_encoded);
                }

                if (settings[0].social && settings[0].social.length > 0) {
                    settings[0].social.forEach((social: { name: string; is_active: any; }) => {
                        if (social.name === 'facebook' && social.is_active) {
                            setActiveFacebook(true);
                        }

                        if (social.name === 'linkedin' && social.is_active) {
                            setActiveLinkedin(true);
                        }

                        if (social.name === 'google' && social.is_active) {
                            setActiveGoogle(true);
                        }
                    });
                }

                if (settings[0].form_login) {
                    if (!settings[0].form_login.email) {
                        setActiveEmail(false);
                    }
                    if (!settings[0].form_login.password) {
                        setActivePassword(false);
                    }
                    if (!settings[0].form_login.only_rs) {
                        setActiveOnly_rs(false);
                        setForm_login('only_rs');
                    }
                }

                setInviteMode(settings[0].invite_mode);
            }
            setIsLoading(false);
        };
        getSettings();

    }, [banners]);

    const handleLanguageChange = (language: string) => {
        changeLenguage(language);
        setSelectedLanguage(language);
        setLocale(language);
        changeTitlesLanguage(language);
    };

    const navigate = useNavigate();
    const handleSubmit = async (values: ILogInForm, formikHelpers: FormikHelpers<any>) => {
        if (inviteMode) {
            const res = await loginDefault();
            if (res)
                navigate('/success', { state: { email: values.email, password: values.password } });
            else
                navigate('/fail');
        } else {
            const res = await loginWithEmail({ email: values.email, password: values.password, method: values.password ? 'email/password' : 'email' });
            if (res)
                navigate('/success', { state: { email: values.email, password: values.password } });
            else
                navigate('/fail');
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = `${API_PORTAL_URL}/google/login?state=${encodedParams}`;
    }

    const handleFacebookLogin = () => {
        window.location.href = `${API_PORTAL_URL}/facebook/login?state=${encodedParams}`;
    }

    const handleLinkedInLogin = () => {
        window.location.href = `${API_PORTAL_URL}/linkedin/login?state=${encodedParams}`;
    }

    const onChange = (event: React.ChangeEvent<any>) => {
        event.persist();
        formik.handleChange(event);
        formik.setFieldTouched(event.target.name, true, false);
    };

    const formik = useFormik(
        {
            initialValues: {
                email: '',
                name: '',
                password: '',
                acceptsTermsConditions: false
            },
            validationSchema: (!inviteMode && activeEmail ? LogInSchema : null),
            onSubmit: handleSubmit,
        }
    );

    useEffect(() => {
        let time = 5000;
        if (banners && banners.length > 0) {
            time = banners[currentIndex].weight ? banners[currentIndex].weight * 100 : 5000;
        }

        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
        }, time); // Cambia el tiempo en milisegundos según lo necesario

        return () => {
            clearInterval(interval);
        }
    }, [currentIndex, banners]);


    const handleBannerClick = async () => {
        try {
            const bannerId = banners[currentIndex]?.id;
            if (bannerId) {
                const clicks = await updateIncrementClicksBanner(bannerId);
                setBannerClicked(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [bannerClicked, setBannerClicked] = useState(false);
    const [form_login, setForm_login] = useState<string>('');

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            {video && (
                <a href={banners[currentIndex]?.redirect_url}
                    target="_blank"
                    onClick={handleBannerClick}
                    style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }} rel="noreferrer">
                    <video loop>
                        <source src={banners[currentIndex]?.file_url} type="video/mp4" style={{
                            backgroundSize: "contain",
                        }} />
                        Tu navegador no admite la reproducción de videos.
                    </video>
                </a>
            )}
            {!video && (defaultImage || banners[currentIndex]?.file_url) && (
                <a href={banners[currentIndex]?.redirect_url}
                    target="_blank"
                    onClick={handleBannerClick}
                    style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }} rel="noreferrer">
                    <img
                        style={{
                            backgroundColor: 'white',
                            height: `${isMobile ? 'auto' : '100vh'}`,
                            width: "100%",
                            backgroundSize: "contain",
                            objectFit: "contain",
                        }}
                        src={banners[currentIndex]?.file_url ? `${banners[currentIndex].file_url}` : (`data:image/jpeg;base64,${defaultImage}`)}
                        alt="logo"
                    />
                </a>
            )}
            {!isLoading && (
                <Container component="main"
                    style={{
                        height: `${isMobile ? 'auto' : '100vh'}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: primaryColor,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: 'white',
                        }}
                    >
                        <WifiIcon sx={{ fontSize: `${isMobile ? '2rem' : '10rem'}`, marginY: '1rem' }} />
                        <Typography component="h1" variant="h3" sx={{ textAlign: 'center' }}>
                            {title}
                        </Typography>
                        <form
                            method='post'
                            action={`${API_PORTAL_URL}/logindefaultaccount?state=${encodedParams}`}
                            style={{
                                marginTop: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {!inviteMode && activeEmail && <TextInput
                                margin="normal"
                                required
                                type="email"
                                value={formik.values.email}
                                onChange={onChange}
                                sx={{ width: '30em', borderRadius: '1rem', color: 'white' }}
                                id="email"
                                label={<FormattedMessage id="email_label" />}
                                name="email"
                                autoComplete="off"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                            />}
                            {!inviteMode && activePassword && <TextInput
                                margin="normal"
                                required
                                type="password"
                                value={formik.values.password}
                                onChange={onChange}
                                sx={{ width: '30rem', borderRadius: '1rem', color: 'white' }}
                                id="password"
                                label={<FormattedMessage id="password" />}
                                name="password"
                                autoComplete="off"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                            />}

                            <Box>
                                {activeGoogle && <IconButton onClick={handleGoogleLogin} size="large" color="secondary"
                                    sx={{ mx: '1rem', my: '1rem', backgroundColor: 'white', boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.15)', '&:hover': { backgroundColor: 'white', border: '1px solid black' } }}>
                                    <GoogleIcon fontSize="inherit" />
                                </IconButton>}
                                {activeLinkedin && <IconButton onClick={handleLinkedInLogin} size="large" color="secondary" sx={{ mx: '1rem', my: '1rem', backgroundColor: 'white', boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.15)', '&:hover': { backgroundColor: 'white', border: '1px solid black' } }}>
                                    <LinkedInIcon fontSize="inherit" />
                                </IconButton>}
                                {activeFacebook && <IconButton onClick={handleFacebookLogin} size="large" color="secondary" sx={{ mx: '1rem', my: '1rem', backgroundColor: 'white', boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.15)', '&:hover': { backgroundColor: 'white', border: '1px solid black' } }}>
                                    <FacebookRoundedIcon fontSize="inherit" />
                                </IconButton>}
                            </Box>

                            <Button
                                id="Entrar"
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{
                                    my: 3,
                                    py: '1rem',
                                    px: '3rem',
                                    borderRadius: '2rem',
                                    color: primaryColor,
                                    fontSize: '1.2rem',
                                    fontWeight: '800',
                                    '&:hover': {
                                        backgroundColor: 'white'
                                    }
                                }}
                                disableElevation
                                disabled={form_login !== "only_rs"}
                            >
                                <FormattedMessage id="get_in_btn" />

                            </Button>
                        </form>
                        <LanguageSelector selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
                    </Box>
                </Container>
            )}
            <FloatingImageWhite />
        </>
    );
}

export default PreLogIn;